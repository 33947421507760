// Object key is the id of the entry in Hygraph
export const shopfrontAnalyticsEvents = {
  // help centre link
  cldjfzg0g03ay0a44kq15r9cf: {
    eventName: 'content_element_pressed',
    elementDesign: 'link',
    elementText: 'help centre',
    elementAction: 'internal redirect',
    actionOrigin: 'shopfront - hero',
    entryPoint: 'shopfront - hero',
  },
  // whatsapp link - this is explicity set inside hygraph UI in the rich text link attribute ID
  whatsapp_link: {
    eventName: 'content_element_pressed',
    elementDesign: 'link',
    elementText: 'WhatsApp',
    elementAction: 'internal redirect',
    actionOrigin: 'shopfront - hero',
    entryPoint: 'shopfront - hero',
  },
  // product spotlight SRN block CTA
  cm5lqrh0zblh60741w43o2vou: {
    eventName: 'content_element_pressed',
    elementDesign: 'button',
    elementText: 'go to SEEK Recruiter Network',
    elementAction: 'internal redirect',
    actionOrigin: 'shopfront - recruiter network',
    entryPoint: 'shopfront - recruiter network',
  },
  // product spotlight freemium block CTA
  cm5kn7iq6glde0743whgp9w8h: {
    eventName: 'content_element_pressed',
    elementDesign: 'button',
    elementText: 'see job ads',
    elementAction: 'internal redirect',
    actionOrigin: 'shopfront - ad products',
    entryPoint: 'shopfront - ad products',
  },
  // talent search container CTA
  clh7ahvzo2jxd0b2qxkkwe39h: {
    eventName: 'content_element_pressed',
    elementDesign: 'button',
    elementText: 'find out about seek talent search',
    elementAction: 'internal redirect',
    actionOrigin: 'shopfront - candidate recommendations',
    entryPoint: 'shopfront - candidate recommendations',
  },
  // hiring advice container CTA
  clh7aporl2jzk0b2i97c99yj0: {
    eventName: 'content_element_pressed',
    elementDesign: 'button',
    elementText: 'get hiring advice',
    elementAction: 'internal redirect',
    actionOrigin: 'shopfront - hiring advice',
    entryPoint: 'shopfront - hiring advice',
  },
  // create account button
  clh8e961134jw0b2lzl7fjtlz: {
    eventName: 'content_element_pressed',
    elementDesign: 'button',
    elementText: 'create account',
    elementAction: 'internal redirect',
    actionOrigin:
      'shopfront - find the right people | shopfront - 3 simple steps',
    entryPoint:
      'shopfront - find the right people | shopfront - 3 simple steps',
  },
  // view home page
  home_page_view: {
    siteSection: 'shopfront',
    eventName: 'shopfront_displayed',
  },
  // register email interaction
  form_first_interaction_pressed: {
    eventName: 'form_first_interaction_pressed',
    actionOrigin: 'shopfront - email registration',
    entryPoint: 'shopfront - email registration',
  },
  // register email validation fail
  form_validation_error_displayed: {
    eventName: 'form_validation_error_displayed',
    validationType: 'field',
  },
  // register email button clicked
  registration_button_pressed: {
    eventName: 'registration_button_pressed',
    actionOrigin: 'shopfront - email registration',
    entryPoint: 'shopfront - email registration',
  },
  // SG benefits
  benefit_1_sg: {
    eventName: 'content_element_pressed',
    elementDesign: 'link',
    elementText: 'unlimited free job ads',
    elementAction: 'internal redirect',
    actionOrigin: 'shopfront - sg benefits',
    entryPoint: 'shopfront - sg benefits',
  },
  benefit_2_sg: {
    eventName: 'content_element_pressed',
    elementDesign: 'link',
    elementText: 'SEEK Pass',
    elementAction: 'internal redirect',
    actionOrigin: 'shopfront - sg benefits',
    entryPoint: 'shopfront - sg benefits',
  },
  benefit_3_sg: {
    eventName: 'content_element_pressed',
    elementDesign: 'link',
    elementText: 'SmartHire',
    elementAction: 'internal redirect',
    actionOrigin: 'shopfront - sg benefits',
    entryPoint: 'shopfront - sg benefits',
  },
};
